import classNames from 'classnames'
import {useRef, useState} from "react";

import Textarea from '@UI/Textarea'
import UploadFile from "@UI/UploadFile";
import {UploadMultipleFiles} from '@UI/UploadMultipleFiles/UploadMultipleFiles';
import {parseAcceptArray} from '@/utils/parseAcceptArray';
import { AnswerButton } from '../AnswerButton/AnswerButton'
import { useTaskHomeWork } from '../hooks/useTaskHomeWork'
import s from './DetailedResponse.module.css'



export const DetailedResponse = ({ type, ...props }) => {
  switch (type) {
    case 'decided': return <Decided {...props} />
    case 'correction': return <Correction {...props} />
    default: return <Task {...props} />
  }
}

const Correction = () => {
  return <div className={s.wrapper}>
    <Textarea className={classNames(s.textarea, s.textareaCorrection)} placeholder='Ответьте развернуто' disabled={true} />
    <UploadFile allowed={false} />
  </div>
}

const Decided = ({userRole, ...props}) => {
  const answer = props?.params?.result?.answer
  const files = props?.params?.result?.answer_files ?? []

  return (
      <div className={s.answerWrap}>
        <div className={s.description}>
          <span className={classNames(s.capsuleLeft, {[s.red]: false})}>
            {userRole === 'children' ? 'Ваш ответ' : 'Ответ ребёнка'}
          </span>

          {answer ? (
              <Textarea className={classNames(s.textarea, s.textareaCorrection)} placeholder='Ответьте развернуто' disabled={true} value={answer}/>
          ) : (
              <span className='error'>Нет ответа</span>
          )}

          {files.map(file => (
              <a key={file} href={process.env.REACT_APP_PATH_FILE + '/' + file.path} className={s.capsuleRight} target='_blank' rel="noreferrer">Открыть файл</a>
          ))}
        </div>
      </div>
  )
}

const Task = ({ params, ...props }) => {
  const textareaRef = useRef();
  const [files, setFiles] = useState([])
  const [isEditing, setIsEditing] = useState(false);
  const { onAnswer, answer, status, hint, video } = useTaskHomeWork(params, props, true, files, false, setIsEditing)

  const accept = parseAcceptArray(props?.mime)
  const defaultFiles = params?.result?.answer_files ?? []

  const onInputFiles = (files) => {
    if (!answer.get && files.length > 0) {
      answer.set('Ответ файлом')
    }
    setFiles(files)
  }

  const enableEditing = () => {
    setIsEditing(true);
  };

  return <div className={s.answerWrap}>
    <Textarea
      ref={textareaRef}
      value={answer.get}
      onInput={e => answer.set(e.target.value)}
      className={s.textarea}
      placeholder='Ответьте развернуто'
      disabled={status > 4 && !isEditing}
    />

    <div style={{marginBottom: 10}}>
      <UploadMultipleFiles onInput={onInputFiles} disabled={status > 4 && !isEditing} accept={accept} maxFiles={10} defaultFiles={defaultFiles} />
    </div>
    <div className={s.actionContainer}>
      <AnswerButton {...{ status, onAnswer, hint, video, isEditing }} />
      {!isEditing && status > 4 && (
        <button className='btn' onClick={enableEditing}>
          Изменить ответ
        </button>
      )}
    </div>
  </div>
}
